.badgescard,
.firstinfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}


.card {
    padding: 20px;
    border-radius: 3px;
    background-color: white;
    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
    position: relative;
    overflow: hidden;
}

.card:after {
    content: '';
    display: block;
    width: 190px;
    height: 300px;
    background: rgba(37, 80, 37, 0.28);
    position: absolute;
    animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
}

.badgescard {
    padding: 10px 20px;
    border-radius: 3px;
    background-color: rgb(37, 80, 37);
    color: #fff;
    width: 480px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: 10px;
    animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards;
}

.badgescard span {
    font-size: 1.6em;
    margin: 0px 6px;
    opacity: 0.6;
}

.firstinfo {
    flex-direction: row;
    z-index: 2;
    position: relative;
}

.firstinfo img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.firstinfo .profileinfo {
    padding: 0px 20px;
}

.firstinfo .profileinfo h1 {
    font-size: 1.8em;
}

.firstinfo .profileinfo h3 {
    font-size: 1.2em;
    color: #ad081f;
}

.firstinfo .profileinfo p.bio {
    padding: 10px 0px;
    color: #5A5A5A;
    line-height: 1.2;
    font-style: initial;
}

.contentcv {
    background-color: white;
}




@keyframes rotatemagic {
    0% {
        opacity: 0;
        transform: rotate(0deg);
        top: -24px;
        left: -253px;
    }

    100% {
        transform: rotate(-30deg);
        top: -24px;
        left: -78px;
    }
}