html {
    height: 100%;
    overflow-x: hidden;
    touch-action: manipulation;
}

body {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-wrap: break-word;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
img,
inpot,
button {
    margin: 0;
    padding: 0;
}

header ul {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.5;
    font-weight: inherit;
}

p {
    line-height: 1.6;
    font-size: 1em;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    color: #555
}

h1 {
    font-size: 4em;
    line-height: 1;
}

h2 {
    font-size: 2.8em;
    line-height: 1.1;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.5em;
}

h5 {
    font-size: 1.2em;
}

h6 {
    font-size: .9em;
    letter-spacing: 1px;
}

a,
button {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    transition: all .3s;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
    text-decoration: none;
    color: #0a0c0a;
}

b {
    font-weight: 500;
}

img {
    width: 100%;
}

.main-menu li {
    list-style: none;
    display: inline-block;
}


button {
    outline: 0;
    border: 0;
    background: none;
    cursor: pointer;
}

b.light-color {
    color: #444;
}



/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

.btn {
    font-size: .9em;
    border-radius: 1px;
    letter-spacing: 2px;
    width: 100%;
    border: 1px solid #047104;
    background: #fff;
    color: #047104;
}

/* .btn:hover{ color: #fff; background: #047104; } */

.btn-2 {
    background: none;
    color: #255025;
    border: 1px solid #255025;
}

.btn-2:hover {
    background: #255025;
    color: #fff;
}

.btn-3 {
    background: none;
    color: #594c06;
    border: 1px solid #594c06;
}

.btn-3:hover {
    background: #594c06;
    color: #fff;
}

.btn-4 {
    background: none;
    color: #ad081f;
    border: 1px solid #ad081f;
}

.btn-4:hover {
    background: #ad081f;
    color: #fff;
}

.section {
    padding: 70px 0 40px;
}

.section .heading {
    padding-bottom: 70px;
}

.center-text {
    text-align: center;
}

.color-white {
    color: #fff;
}

.display-table {
    display: table;
    height: 100%;
    width: 100%;
}

.display-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.card {
    background: transparent;
    border: 0;
}

.no-side-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.no-left-padding {
    padding-left: 0px;
}

.no-right-padding {
    padding-right: 0px;
}

.icon {
    font-size: 2em;
}


.left-area {
    float: left;
}

.right-area {
    float: right;
}

.width-60 {
    width: 60%;
}

.width-50 {
    width: 50%;
}


::-webkit-input-placeholder {
    font-size: .9em;
    letter-spacing: 1px;
}

::-moz-placeholder {
    font-size: .9em;
    letter-spacing: 1px;
}

:-ms-input-placeholder {
    font-size: .9em;
    letter-spacing: 1px;
}

:-moz-placeholder {
    font-size: .9em;
    letter-spacing: 1px;
}



/* ---------------------------------
3. MENU
--------------------------------- */

header {
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    font-size: .95em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .3);
    background: #fff;
}


/* TOP MENU */

header .top-menu {
    width: 100%;
    height: 45px;
    line-height: 45px;
    overflow: hidden;
    font-size: .95em;
    font-style: italic;
}


header .top-menu ul.welcome-area {
    padding-left: 20px;
}

header .top-menu ul.welcome-area>li {
    margin: 0 15px;
    padding: 0 15px;
    border-left: 1px solid #ddd;
}


header .top-menu ul>li:first-child {
    border-left: 0;
}

header .top-menu ul.social-icons {
    display: inline-block;
    padding: 0 35px;
    color: #aaa;
}

header .top-menu ul.social-icons>li>a {
    padding: 0 15px;
}



/* SEARCH AREA */

header .src-area {
    position: relative;
    height: 45px;
    width: 250px;
    display: inline-block;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background: #fff;
}

header .src-area .src-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 40px 0 20px;
    background: transparent;
    border: 0;
    outline: 0;
}

header .src-area .src-input:focus {
    box-shadow: 0px 0px 1px #aaa;
}

header .src-area .src-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    font-size: 1.2em;
    opacity: .6;
    z-index: 10;
}



/* MIDDLE AREA */

header .middle-menu {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: auto;
}

header .logo {
    padding: 0px 0;
}

header .logo img {
    width: auto;
}


/* BOTTOM AREA */

header .bottom-area {
    text-align: center;
}

header .main-menu {
    font-weight: 500;
}

header .main-menu>li>a {
    height: 60px;
    line-height: 60px;
    padding: 0 25px;
    font-weight: bold;
}

header .main-menu>li>a:hover {
    background: #eee;
}


header .visible.main-menu {
    display: block;
}



/* DROPDOWN MENU STYLING */

header .main-menu li.drop-down {
    position: relative;
    text-align: left;
}

header .main-menu li.drop-down>ul.drop-down-menu {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 200px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, .3);
    background: #fff;
}

header .main-menu li.drop-down>ul.drop-down-menu li {
    display: block;
    border-top: 1px solid #ddd;
}

header .main-menu li.drop-down>ul.drop-down-menu li>a {
    display: block;
    padding: 12.5px 7px;
}

header .main-menu li.drop-down>ul.drop-down-inner li:first-child {
    border-top: 0;
}

header .main-menu li i {
    margin-left: 10px;
}



/* DROPDOWN SUBMENU */

header .main-menu li.drop-down>ul.drop-down-inner {
    top: 0;
    left: auto;
    left: 100%;
}



/* DROPDOWN HOVER */

header .main-menu li a.mouseover {
    background: #F2F3F4;
}

header .main-menu li.drop-down a.mouseover+ul.drop-down-menu {
    display: block;
    animation: full-opacity-anim .2s forwards;
}


@keyframes full-opacity-anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* NAV ICON */

.menu-nav-icon {
    display: none;
    width: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 1.8em;
}



/* ---------------------------------
4. SLIDER
--------------------------------- */

.main-slider {
    margin-top: 265px;
    position: relative;
    height: calc(100vh - 265px);
    min-height: 550px;
    width: 100%;
    overflow: hidden;
}

.main-slider .slider-content {
    padding: 25px 60px 25px 40px !important;
    border-radius: 3px !important;
    background: rgba(0, 0, 0, .6);
    color: #fff;
}

.slider-content .btn {
    padding: 7px 15px;
}

.slider-content .title {
    margin: 10px 0 0;
    letter-spacing: 3px;
}


#slider {
    height: 100%;
    width: 100%;
}

.main-slider .ls-nav-prev,
.main-slider .ls-nav-next {
    display: block !important;
    opacity: 1 !important;
}


/* ---------------------------------
5. SINGLE POST
--------------------------------- */

.blog-posts .single-post {
    margin-bottom: 60px;
}

.single-post .icons {
    overflow: hidden;
    line-height: 30px;
    margin: 30px 0 10px;
}

.single-post .icons .caegory-btn {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
}

.single-post .icons ul.social-icons {
    color: #666;
}

.single-post .icons ul.social-icons>li>a {
    padding-left: 15px;
}

.single-post .icons ul.social-icons>li>a>i {
    margin-right: 10px;
}

.single-post .title {
    margin: 20px 0 15px;
}

.single-post .date {
    color: #999;
}

.single-post .read-more-btn {
    clear: both;
    margin-top: 30px;
    box-shadow: 1px 10px 15px rgba(0, 0, 0, .15);
    border: 1px solid #047104;
    background: #fff;
    color: #047104;
}

.single-post .read-more-btn:hover {
    box-shadow: 1px 3px 15px rgba(0, 0, 0, .15);
    background: #047104;
    color: #fff;
}


/* POST STYLE TWO */

.post-style-2 .image-wrapper {
    padding-right: 30px;
}

.post-style-2 .post-details .icons {
    margin-top: 0;
}

.post-style-2 .post-details .icons .caegory-btn {
    margin: 0;
}


/* LOAD MORE BUTTON */

.blog-posts .load-more-btn {
    clear: both;
    margin: 70px 0 30px;
}


/* ---------------------------------
6. SIDEBAR STYLING
--------------------------------- */

.sidebar-section {
    margin-bottom: 50px;
}

.sidebar-section .title {
    margin-bottom: 30px;
}

.sidebar-area img {
    max-width: 600px;
}


/* ABOUT AUTHOR */

.about-author {
    margin-top: 30px;
    padding: 0 30px 30px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, .2);
}

.about-author .author-image {
    max-width: 150px;
    margin: 0px auto 20px;
    transform: translateY(-30px);
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .1);
}

.about-author .social-icons>li {
    margin: 0 10px;
    color: #888;
    font-size: 1.05em;
}

.about-author .author-name {
    margin: 20px 0;
}

.about-author .signature-image {
    margin: 20px auto 0;
    max-width: 120px;
}

.about-author .read-more-link {
    margin: 20px 0;
    font-size: .9em;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    color: #047104;
}

.about-author .read-more-link:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 1px;
    transition: all .2s;
    background: #047104;
}

.about-author .read-more-link:hover:after {
    transform: translateX(100%);
}


/* SEARCH AREA */

.src-area {
    line-height: 50px;
    position: relative;
}

.src-area input {
    display: block;
    width: 100%;
    padding: 0 50px 0 20px;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #bbb;
}

.src-area .src-btn {
    position: absolute;
    top: 0;
    right: 20px;
    height: 50px;
}


/* NEWSLETTER AREA */

.newsletter-area {
    padding: 30px;
    background: #29333C;
}

.newsletter-area .title {
    margin: 0;
    color: #fff;
}

.newsletter-area .email-input {
    width: 100%;
    margin: 15px 0;
    border: 0;
    padding: 10px 20px;
    background: #fff;
}


/* CATEGORY AREA */

.category-area .category {
    position: relative;
    margin-bottom: 20px;
}

.category .name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 7px 20px;
    background: #047104;
    color: #fff;
}


/* LATEST POSTS */

.latest-post {
    overflow: hidden;
    margin-bottom: 20px;
}

.latest-post .l-post-image {
    width: 80px;
    float: left;
}

.latest-post .post-info {
    margin-left: 100px;
}

.latest-post .category-btn {
    padding: 5px 10px;
    margin-bottom: 5px;
}

.latest-post .date {
    margin-top: 5px;
    color: #888;
}


/* ADVERTISEMENT AREA */

.advertisement-img {
    position: relative;
}

.advertisement-img .discover-btn {
    position: absolute;
    bottom: 30px;
    padding: 15px 0;
    width: 200px;
    left: 50%;
    margin-left: -100px;
    border-color: #fff;
    color: #fff;
}

.advertisement-img .discover-btn:hover {
    border-color: #047104;
}


/* INSTAGRAM */

.instagram-area {
    overflow: hidden;
}

.instagram-area .instagram-img>li {
    width: 30%;
    float: left;
    margin: 0 5px 5px 0;
}


/* TAGS */

.tags-area .tags {
    overflow: hidden;
}

.tags-area .tags>li {
    float: left;
    margin: 0 5px 5px 0;
}

.tags-area .tags>li>a {
    padding: 10px 15px;
}


/* ---------------------------------
7. FOOTER INSTAGRAM STYLING 
--------------------------------- */

.footer-instagram-area {
    overflow: hidden;
    border-top: 1px solid #ccc;
}

.footer-instagram-area .title {
    padding: 20px 0;
}

.footer-instagram-area ul.instagram>li {
    float: left;
    width: 14.285%;
}


/* ---------------------------------
8. FOOTER
--------------------------------- */

footer {
    background: #29333C;
    color: #ddd;
}

footer .footer-section {
    margin: 30px 0;
}

footer .copyright {
    color: #ddd;
}

footer .social-icons {
    text-align: right;
}

footer .social-icons>li>a {
    padding-left: 15px;
}

.offerTitle {
    text-align: center;

    border: solid 2px;
    border-color: black;
    border-left: 0;
    border-right: 0;
}

.emploi {
    color: white;
}

.appels {
    color: black;
}

#myBtn {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Fixed/sticky position */
    bottom: 20px;
    /* Place the button at the bottom of the page */
    right: 30px;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    border: none;
    /* Remove borders */
    outline: none;
    /* Remove outline */
    background-color: #c30a0a;
    /* Set a background color */
    color: white;
    /* Text color */
    cursor: pointer;
    /* Add a mouse pointer on hover */
    padding: 15px;
    /* Some padding */
    border-top-right-radius: 100px;
    /* Rounded corners */
    font-size: 18px;
    /* Increase font size */
}

#myBtn:hover {
    background-color: #047104;
    ;
    /* Add a dark-grey background on hover */
}

.nav .nav-tab {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
}



section {
    padding-top: 4rem;
    padding-bottom: 5rem;
    background-color: #f1f4fa;
}

.wrap {
    display: flex;
    background: white;
    padding-top: 1px;
    margin-bottom: 0.25rem;
    min-height: 110px;
}

.wrap:hover {
    background: #eee;
    color: black;
}

.ico-wrap {
    margin: auto;
}

.mbr-iconfont {
    font-size: 4.5rem !important;
    color: #313131;
    margin: 1rem;
    padding-right: 1rem;
}

.vcenter {
    margin: auto;
}

.mbr-section-title3 {
    text-align: left;
}

h2 {
    margin-bottom: 0.5rem;
}

.display-5 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1rem;
}

.mbr-bold {
    font-weight: 700;
}

p {
    line-height: 25px;
}

.display-6 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1em;
}

.note-toolbar .btn {
    background: none;
    border: 1px solid #343f34;
    color: #000;
    padding: 5px;
}

.note-popover {
    display: none;
}

.btn-facebook {
    color: #fff;
    background-color: #4C67A1;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #405D9B;
}

.btn-facebook:focus {
    color: #fff;
}

.logo {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

hr {
    margin: 0;
    border: 1px solid;
    color: red;
    opacity: 1;
}

.titleSite {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.titleSiteAr {
    font-family: system-ui;
}

.navBar {
    background-color: rgb(37, 80, 37);
    color: white;
}

.menuHome {
    padding-top: 20px;
}

button#controlled-tab-example-tab-OffresEmploi {
    color: black;
    font-weight: bold;
}

button#controlled-tab-example-tab-AppelsOffres {
    color: black;
    font-weight: bold;
}

button#controlled-tab-example-tab-Avis {
    color: black;
    font-weight: bold;
}




button#controlled-tab-example-tab-OffresEmploi.active {
    background-color: rgb(37, 80, 37);
    color: #fff;
    border-top: 2px #fff solid;
    border-bottom: 2px #fff solid;
}

button#controlled-tab-example-tab-AppelsOffres.active {
    background-color: rgb(173, 8, 31);
    color: #fff;
    border-top: 2px #fff solid;
    border-bottom: 2px #fff solid;

}

button#controlled-tab-example-tab-Avis.active {
    background-color: rgb(89, 76, 6);
    color: #fff;
    border-top: 2px #fff solid;
    border-bottom: 2px #fff solid;

}

#controlled-tab-example-tabpane-AppelsOffres.active {
    border: 2px solid rgb(173, 8, 31);
}

#controlled-tab-example-tabpane-OffresEmploi.active {
    border: 2px solid rgb(37, 80, 37);
}

#controlled-tab-example-tabpane-Avis.active {
    border: 2px solid rgb(89, 76, 6);
}

.avis p a {
    color: #000;
    text-decoration: none;
}

.avis p {
    margin-bottom: 0px;
}

.titleAvis {
    color: #000;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
}

.tab-content {
    background-color: #eee;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.wrap-cv {
    display: flex;
    background: white;
    padding-top: 1rem;
    /* margin-bottom: 0.25rem; */
    min-height: 150px;
}

.accordion-item:first-of-type .accordion-button {
    background-color: #eee;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: #eee;
}

.dropdown-item {
    border-bottom: 2px #eee solid;
    height: 40px;
}

.dropdown-menu {
    --bs-dropdown-padding-y: 0rem;
}

.btn-langue {
    border-radius: 10%;
    margin: 2px 5px 0px 5px;
    border: 2px #EEE solid;
    padding: 6px;
    color: black;
}

.btn-langue:hover {
    color: black;
    background-color: #fff;
    border-color: #eee;
}

.btn-langue::after {
    display: none;
}